




import { Component, Vue, Watch } from 'vue-property-decorator';
import FlowViewer from './flow-viewer.vue';
import { readRuntimeTask } from '@/api/flow-design/FlowApi';

@Component({
  name: 'FlowPossibility',
  components: { FlowViewer }
})
export default class FlowStart extends Vue {
  operation: string = 'task_possibility';
  get _taskDataId() {
    return this.$route.query.id;
  }
  private created(): void {
    readRuntimeTask(this.$route.query.id as string).then((res: Record<any, any>) => {
      if (res.code != '1') {
        this.$message.error(res.message);
      }
    });
  }
}
